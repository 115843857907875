import React from 'react';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import NotLead from './NotLead';

interface LeadFilterProps {
  visitorRoute: boolean;
}

export const LeadFilter: React.FC<LeadFilterProps> = ({ visitorRoute, children }) => {
  const isAuthenticated = useIsAuthenticated();

  if (visitorRoute && (isAuthenticated.asOperator || isAuthenticated.asPartnerAgent)) {
    return (
      <NotLead
        isPartnerAgent={isAuthenticated.asPartnerAgent}
        isOperator={isAuthenticated.asOperator}
        isCustomer={isAuthenticated.asCustomer}
      />
    );
  }

  return <>{children}</>;
};

export default LeadFilter;
