import Link from 'next/link';
import React from 'react';
import config from '../config';
import type { LinkProps } from 'next/link';

// Only allow either href or to
// type HybridLinkProps = Omit<React.HTMLAttributes<HTMLAnchorElement>, 'href'> &
//   ({ href: string } | { to: string });

// Allow both as optional: then you can do `links.map(myLink => <HybridLink {...myLink} />)`
type HybridLinkProps = Omit<
  LinkProps &
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'href'
> & {
  href?: string;
  to?: string;
};

/**
 * Acts as a Link element when `href` is passed, and as a Next Link when `to` is passed
 *
 * TODO: could also only accept "href" and check if it starts with / or http or www.
 */
const HybridLink: React.FC<HybridLinkProps> = props => {
  if (!config.isProduction) {
    if ('to' in props && !!props.to && 'href' in props && !!(props as any).href) {
      console.warn(
        'HybridLink was passed both a "to" and "href" props, this should never happen!',
        {
          to: (props as any).to,
          href: (props as any).href,
        },
      );
    } else if (props.href?.startsWith('/')) {
      console.warn(
        'An "href" prop starting with / was passed to HybridLink, this should probably be the "to" prop: ',
        props.href,
      );
    } else if (props.to && !props.to?.startsWith('/') && props.to !== '#') {
      console.warn(
        'A "to" prop that doesn not start with / was passed to HybridLink, this should probably be the "href" prop: ',
        props.to,
      );
    }
  }
  if ('to' in props && !!props.to) {
    const { to, prefetch, as, replace, scroll, shallow, passHref, locale, ...anchorProps } = props;
    const linkProps = { prefetch, as, replace, scroll, shallow, passHref, locale };
    return (
      <Link href={props.to} {...linkProps}>
        <a {...anchorProps} />
      </Link>
    );
  }
  return <a {...props} />;
};

export default HybridLink;
