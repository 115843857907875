import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

type ContextValue = [string, Dispatch<SetStateAction<string>> | null];

const initialLeadEmail = '';
export const LeadEmailContext = React.createContext<ContextValue>([initialLeadEmail, null]);

export const LeadEmailProvider: React.FC = ({ children }) => {
  const [leadEmail, setLeadEmail] = useState(initialLeadEmail);
  const contextValue: ContextValue = [leadEmail, setLeadEmail];
  return <LeadEmailContext.Provider value={contextValue}>{children}</LeadEmailContext.Provider>;
};

export function useLeadEmail() {
  const [leadEmail, setLeadEmail] = useContext(LeadEmailContext);

  const localStorageEmail =
    typeof window !== 'undefined' ? window.localStorage?.getItem('resultsEmail') ?? '' : '';
  const handleSetLeadEmail = (email: string) => {
    setLeadEmail?.(email);
    window?.localStorage?.setItem('resultsEmail', email);
  };

  return {
    leadEmail: leadEmail || localStorageEmail,
    setLeadEmail: handleSetLeadEmail!,
  };
}
