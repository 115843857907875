import { ApolloLink } from '@apollo/client';

function omitDeepArrayWalk(arr, key) {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === 'object') return omitDeep(val, key); // eslint-disable-line
    return val;
  });
}

function omitDeep(obj, key) {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
}

/**
 * Used to remove __typename from variables, which cause mutations to fail
 * From: https://gist.github.com/cdelgadob/4041818430bc5802016332dbe5611570
 * More info: https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
 */
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => {
    return data;
  });
});

export default cleanTypenameLink;
