import { gql } from '@apollo/client';

export const HouseEnergyFragment = gql`
  fragment HouseEnergy on HouseEnergy {
    gasConsumption
    electricityProduction
    electricityConsumption
    energyIndex
  }
`;
