import { useEffect } from 'react';
import usePathname from '~/hooks/usePathname';

const trackPage = (pathname: string) => {
  if (typeof window === 'undefined' || !window.dataLayer) return;

  // Store pageviews in localStorage, so we can pass those to JourneyEvent triggers where necessary
  const localPageViews = window.localStorage.getItem('pageViews');
  const pageViews = localPageViews ? JSON.parse(localPageViews) : [];

  // Prevent double entries on refresh / rerender
  if (pageViews[0] !== pathname) pageViews.unshift(pathname);
  window.localStorage.setItem('pageViews', JSON.stringify(pageViews));
};

const usePageViewsTracker = () => {
  const pathname = usePathname();

  const cleanedPathname =
    pathname !== '/' && pathname.endsWith('/')
      ? pathname.substring(0, pathname.length - 1)
      : pathname;

  useEffect(() => {
    const url = new URL(location.href);
    url.searchParams.delete('magicLink');
    url.searchParams.delete('data'); // used for password resetting

    // Note: using location.href intentionally here, since we also want to capture params
    const baseUrl = location.protocol.concat('//').concat(location.host);
    const cleanedHref = url.href.replace(baseUrl, '');

    trackPage(cleanedHref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanedPathname]);
};

export default usePageViewsTracker;
