import { gql, useLazyQuery } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import type { getABTestEntry, getABTestEntryVariables } from '~/types/generated/getABTestEntry';
import { useMe } from './useMe';
import type { Dispatch, SetStateAction } from 'react';

/** This creates an entry if it cannot find one – for an existing domain */
export const GET_AB_TEST_VARIANT = gql`
  query getABTestEntry($userId: ID!, $domain: String!) {
    getABTestEntry(userId: $userId, domain: $domain) {
      id
      domain
      experiment
      variant
      subvariant
    }
  }
`;

export type ABTestEntry = {
  id: string;
  domain: string;
  experiment: string;
  variant: string;
  subvariant: number;
};

type ContextValue = [ABTestEntry[], Dispatch<SetStateAction<ABTestEntry[]>> | null];

const initialValue: ABTestEntry[] = [];
export const AbTestEntriesContext = React.createContext<ContextValue>([initialValue, null]);

export const AbTestEntryProvider: React.FC = ({ children }) => {
  const [abTestEntries, setAbTestEntries] = useState<ABTestEntry[]>(initialValue);
  const contextValue: ContextValue = [abTestEntries, setAbTestEntries];
  return (
    <AbTestEntriesContext.Provider value={contextValue}>{children}</AbTestEntriesContext.Provider>
  );
};

export default function useABTest() {
  const { me } = useMe();
  const [abTestEntries, setAbTestEntries] = useContext(AbTestEntriesContext);

  const [createAbTestEntry, { loading: abLoading, error: abError }] = useLazyQuery<
    getABTestEntry,
    getABTestEntryVariables
  >(GET_AB_TEST_VARIANT, {
    onCompleted: data =>
      data.getABTestEntry && setAbTestEntries?.([...abTestEntries, data.getABTestEntry]),
    onError: e => console.error(e),
  });

  const getAbTestEntry = useCallback(
    (domain: string) => {
      if (abLoading || abError) return;
      if (abTestEntries.some(entry => entry.domain === domain)) return abTestEntries;
      if (me?.id) void createAbTestEntry({ variables: { userId: me.id, domain } });
      return abTestEntries;
    },
    [abError, abLoading, abTestEntries, createAbTestEntry, me?.id],
  );

  const localStorageEntries =
    typeof window !== 'undefined' && window.localStorage?.getItem('abEntries')
      ? (JSON.parse(window.localStorage.getItem('abEntries')!) as ABTestEntry[])
      : initialValue;

  const handleSetAbTestEntries = (entries: ABTestEntry[]) => {
    setAbTestEntries?.(entries);
    // Also store entries in localStorage so we can reinitialize correctly on reload
    entries
      ? window.localStorage.setItem('abEntries', JSON.stringify(entries))
      : window.localStorage.removeItem('abEntries');
  };

  return {
    abTestEntries: localStorageEntries || abTestEntries,
    setAbTestEntries: handleSetAbTestEntries,
    getAbTestEntry,
  };
}
