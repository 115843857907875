import React, { useRef, useEffect, useContext } from 'react';
import { useState } from 'react';
import Toaster from '../components/Toaster';

type ToastType = 'success' | 'error' | 'info' | 'warning';
export interface IToast {
  /** The text to show in the toast notification. Will auto-collapse when it exceeds certain length.
   * Try to keep it short and concise, and keep in mind that the duration needs to cover reading and understanding it. */
  message: string;
  /** Type of notification. Different colors and icons to match. Defaults to `info`. */
  type?: ToastType;
  /** How long to show the toast message for in ms. Defaults to `5000`. Setting this to `0` disables a timed self-destruct. */
  duration?: number;
  /** Unique ID per toast, set automatically. */
  uid: number;
}

export interface IToastInput {
  message: string;
  type?: ToastType;
  duration?: number;
}

export type PopToast = (toast: IToastInput | IToastInput[]) => void;
export const ToasterContext = React.createContext<PopToast>(null as any);

export const ToasterProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [nextUid, setNextUid] = useState(0);

  /** Using a ref to ensure the Toaster doesn't rerender every time we update the state,
   * so the array of toasts and their respective UIDs stays intact
   */
  const toastsRef = useRef(toasts);

  useEffect(() => {
    if (toastsRef.current !== toasts) {
      toastsRef.current = toasts;
    }
  }, [toasts]);

  const addToast = (toast: IToastInput | IToastInput[]) => {
    // Asynchronous state setting means that you cannot add multiple toasts synchonously because the
    // uid state does not update in time. Must therefore be able to pass array if we want to do multiple
    // toasts at once (sigh)

    if (Array.isArray(toast)) {
      const newToasts: IToast[] = toast.map((toast, i) => ({
        ...toast,
        uid: nextUid + 1 + i,
        duration: 5000,
      }));

      setToasts([...toastsRef.current, ...newToasts]);
      setNextUid(nextUid + toast.length);
    } else {
      const uid = nextUid;
      const newToast: IToast = {
        ...toast,
        uid,
        duration: 5000,
      };
      setNextUid(uid + 1);
      setToasts([...toastsRef.current, newToast]);
    }
  };

  const removeToast = (uid: number) => setToasts(toasts.filter(toast => toast.uid !== uid));

  return (
    <ToasterContext.Provider value={addToast}>
      {children}
      <Toaster toasts={toasts} removeToast={removeToast} />
    </ToasterContext.Provider>
  );
};

export default function useToaster(): PopToast {
  return useContext(ToasterContext);
}
