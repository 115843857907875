import React, { useContext, useEffect, useState } from 'react';
import { useMe } from './useMe';

interface IsAuthenticatedProps {
  asCustomer: boolean;
  asOperator: boolean;
  asPartnerAgent: boolean;
}

type ContextValue = IsAuthenticatedProps;

const defaultIsAuthenticated: IsAuthenticatedProps = {
  asCustomer: false,
  asOperator: false,
  asPartnerAgent: false,
};

export const IsAuthenticatedContext = React.createContext<ContextValue>(defaultIsAuthenticated);

export const IsAuthenticatedProvider: React.FC<{
  initialIsAuthenticated?: IsAuthenticatedProps;
}> = ({ children, initialIsAuthenticated }) => {
  const { me } = useMe();
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialIsAuthenticated ?? {
      asCustomer: me?.__typename === 'Customer',
      asOperator: me?.__typename === 'Operator',
      asPartnerAgent: me?.__typename === 'PartnerAgent',
    },
  );

  useEffect(() => {
    setIsAuthenticated({
      asCustomer: me?.__typename === 'Customer',
      asOperator: me?.__typename === 'Operator',
      asPartnerAgent: me?.__typename === 'PartnerAgent',
    });
  }, [me?.__typename]);

  const contextValue: ContextValue = isAuthenticated;
  return (
    <IsAuthenticatedContext.Provider value={contextValue}>
      {children}
    </IsAuthenticatedContext.Provider>
  );
};

/** Custom hook to fetch current user id and type  */
export default function useIsAuthenticated() {
  return useContext(IsAuthenticatedContext);
}
