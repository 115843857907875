import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';

const GAClientIdContext = React.createContext('');

export const GAClientIdProvider: React.FC = ({ children }) => {
  const [cookies] = useCookies();
  const gaCookie = cookies['_ga'];

  return (
    <GAClientIdContext.Provider value={gaCookie?.substring(6) || ''}>
      {children}
    </GAClientIdContext.Provider>
  );
};

export function useGAClientId() {
  return useContext(GAClientIdContext);
}
