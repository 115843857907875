import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import usePathname from '~/hooks/usePathname';
import config from '../../config';
import data from './Meta-Tags.json';

type Tag = { type: string; tagID: string; content: string };
type MetaTag = {
  paths: string[];
  title: string;
  tags: Tag[];
  links?: undefined;
};
type Link = { rel: string; href: string; type?: string; sizes?: string };
type LinkTag = {
  paths: string[];
  links: Link[];
  title?: undefined;
  tags?: undefined;
};
type MetaTags = (MetaTag | LinkTag)[];

const metaTags = data.metatags as MetaTags;

const renderMetaTag = (tag: Tag) => (
  <meta
    key={`meta-${tag.tagID}`}
    content={tag.content}
    {...(tag.type === 'name' ? { name: tag.tagID } : { property: tag.tagID })}
  />
);

const renderMetaLink = (link: Link) => <link key={`link-${link.rel}`} {...link} />;

type TagData = {
  tags: Tag[];
  title: string;
  links: Link[];
};

const ignoredPaths = [
  'blog',
  'dato',
  'faq',
  'diensten',
  'campagne',
  'mijn-',
  'samen',
  'klanten',
  'woning-verduurzamen',
];

function getTagData(pathname: string): TagData {
  const routeTag = metaTags.find(tag => tag.paths.includes(pathname));

  const tagData: TagData = {
    tags: [],
    title: 'Energiebespaarders • Duurzaam wonen zonder gedoe.',
    links: [],
  };

  if (routeTag) {
    if (routeTag.tags) tagData.tags = routeTag.tags;
    if (routeTag.title) tagData.title = routeTag.title;
    if (routeTag.links) tagData.links = routeTag.links;
  } else if (!config.isProduction && !ignoredPaths.some(path => pathname.includes(path))) {
    console.warn('No meta-tags-entry found in MetaTagLoader for pathname', pathname);
  }

  return tagData;
}

const globalTagData = getTagData('global');

interface MetaTagLoaderProps {
  titleSuffix?: string;
  customPath?: string;
}

const MetaTagLoader: React.FC<MetaTagLoaderProps> = ({ titleSuffix, customPath }) => {
  const pathname = usePathname();

  const [tagData, setTagData] = useState(() => getTagData(customPath || pathname));

  useEffect(() => {
    setTagData(getTagData(customPath || pathname));
  }, [customPath, pathname]);

  const title = tagData.title || globalTagData.title;
  const optionalSuffix = titleSuffix && title.endsWith(titleSuffix) ? '' : titleSuffix;

  return (
    <Head>
      <title key="title">{`${tagData.title || globalTagData.title}${optionalSuffix}`}</title>

      {globalTagData.tags.map(tag => renderMetaTag(tag))}
      {tagData.tags.map(tag => renderMetaTag(tag))}

      {globalTagData.links.map(link => renderMetaLink(link))}
      {tagData.links.map(link => renderMetaLink(link))}
    </Head>
  );
};

export default MetaTagLoader;
