import { css } from 'styled-components';

const print = css`
  @media print {
    body {
      background: none;
      color: black;
      line-height: 18pt;
      letter-spacing: 0.1pt;
      font-size: 12pt;
    }

    @page {
      size: A4;
      padding: 0;
      margin: 12mm 16mm 18mm;
    }
  }
`;

export default print;
