import { useRouter } from 'next/router';
import config from '~/config';
import { RoutesByFilePath } from './useRouteProps';

/**
 * Basically checks router.asPath === router.pathname, but also tries to work with dynamic routes (URL variables)
 * Intended for asPath during prerendering
 *
 * @param asPath E.g. /Solutions/zonnepanelen/kosten
 * @param pathname E.g. /Solutions/[solution]/[topic]
 */
function isAsPathEqualToPathname(asPath: string, pathname: string) {
  if (asPath === pathname) {
    return true;
  }

  const pageSections = pathname.split('/');
  const routeSections = asPath.split('/');

  if (pageSections.length !== routeSections.length) {
    return false;
  }

  // Remove the variables from both pathnames
  for (let i = 0; i < pageSections.length; i++) {
    if (pageSections[i].startsWith('[')) {
      pageSections[i] = '';
      routeSections[i] = '';
    }
  }

  return pageSections.join('/') === routeSections.join('/');
}

/**
 * Intended for client-side: strips of any excess parameters.
 * E.g. `/myPage?param=3#heading1` => `/myPage`
 */
export const getPathnameFromAsPath = (asPath: string) => {
  if (typeof window === 'undefined') {
    const paramSplit = asPath.split('?');
    const hashSplit = paramSplit[0]?.split('#');
    return hashSplit[0];
  }
  return new URL(asPath || '', config.urls.base).pathname;
};

/**
 * We cannot rely on `router.pathname` due to the use of rewrites for routing.
 * This function returns the pathname as expected:
 * e.g. for `/bedankt#section1?param=2` it will return `/bedankt`.
 * Both at run-time and at build-time
 *
 * How it works:
 * Since we use rewrites to manage our routes in Dutch,
 * the `router.pathname` will be the English Page filename.
 * The actual route visited by the browser is set on `router.asPath`,
 * but this also includes query parameters and hashes etc. which will be removed here.
 *
 * On the server (when pre-rendering) this doesn't work however,
 * but we can look up the desired pathname from the routes file.
 */
const usePathname = () => {
  const { asPath, pathname } = useRouter();

  // When prerendering, both pathname and asPath will be the filename of the Page: both will be /Homepage
  // Exception for URL variables handled in helper function up top
  const isPrerendering = typeof window === 'undefined' && isAsPathEqualToPathname(asPath, pathname);

  // When we're _not_ prerendering, we can base the pathname on asPath:
  // the local URL used by the browser
  if (!isPrerendering) {
    return getPathnameFromAsPath(asPath);
  }

  // When prerendering however, we can base it on the routes file: every entry has a "source" field that we rewrite the Page file path to
  const routeProps = RoutesByFilePath.get(pathname);
  if (routeProps) {
    // For paths without variables, just return the source directly
    if (!routeProps.source.includes(':')) {
      return routeProps.source;
    }

    // Otherwise, convert the variables from asPath into the routeProps.source
    // E.g.
    // - router.asPath      /Solutions/isolatieglas/details
    //   router.pathname    /Solutions/[solution]/[topic]
    // - route.source       /energie-besparen/:solution/:topic
    //   route.destination  /Solutions/:solution/:topic
    // - return:            /energie-besparen/isolatieglas/details

    const asPathSections = asPath.split('/');
    const sourceSections = routeProps.source.split('/');

    if (asPathSections.length === sourceSections.length) {
      return sourceSections
        .map((s, i) => (s.startsWith(':') ? asPathSections[i] || s : s))
        .join('/');
    }

    // Take into account source & destination with different amount of sections
    // case 2:
    // - router.asPath      /_campaigns/InstallerPartner/grimbergen
    //   router.pathname    /_campaigns/InstallerPartner/[slug]
    // - route.source       /installatiepartner/:slug
    //   route.destination  /_campaigns/InstallerPartner/:slug
    // - return             /installatiepartner/grimbergen

    const destinationSections = routeProps.destination.split('/');

    for (let i = 0; i < sourceSections.length; i++) {
      if (sourceSections[i].startsWith(':')) {
        const varIndex = destinationSections.indexOf(sourceSections[i]);
        sourceSections[i] = asPathSections[varIndex];
      }
    }

    return sourceSections.join('/');
  }

  // if (!config.isProduction) {
  //   console.warn('Could not determine rewritten pathname', { asPath, pathname });
  // }

  return pathname;
};

export default usePathname;
