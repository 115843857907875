import { gql } from '@apollo/client';

export const DutiesProgressFragment = gql`
  fragment DutiesProgress on Progress {
    duties {
      virtualVisit {
        requested
        paid
        done
        plannedOn
        plannedFor
      }
      visit {
        requested
        paid
        done
        plannedOn
        plannedFor
      }
      adviceReport {
        requested
        paid
        done
        sent
        opened
      }
      energyLabel {
        requested
        paid
        done
        registered
      }
    }
  }
`;

export const DealsProgressFragment = gql`
  fragment DealsProgress on Progress {
    deals {
      solution
      quotes {
        quoteId
        quoteCreated
        quoteCancelled
        quoteAccepted
        quoteSent
        quoteSeen
        jobId
        jobCreated
        plannings {
          created
          jobId
          sent
          plannedOn
          plannedFor
        }
      }
    }
  }
`;

export const FullProgressFragment = gql`
  ${DutiesProgressFragment}
  ${DealsProgressFragment}
  fragment FullProgress on House {
    progress {
      id
      savingsCheckFinished
      resultsPDFSent
      ...DutiesProgress
      ...DealsProgress
    }
  }
`;
