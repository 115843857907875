import { gql } from '@apollo/client';

export const NeedsFragment = gql`
  fragment Needs on CustomerNeeds {
    budget
    anticipatedSlurpers
    extraComfort
    floor {
      underfloorHeating
    }
    heating {
      multiHotWater
      newThermostat
    }
    windows {
      soundproofing
    }
  }
`;

export default NeedsFragment;
