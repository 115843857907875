import React from 'react';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { Box } from '@energiebespaarders/symbols';

const ForgotPasswordConfirmation = ({ email }: { email: string }) => (
  <Box width={1} px={1}>
    <p className="medium text-center">
      Als dat e-mailadres bij ons bekend is, wordt er een e-mail naar <Medium>{email}</Medium>{' '}
      verstuurd met een link om je wachtwoord te herstellen.
    </p>
  </Box>
);

export default ForgotPasswordConfirmation;
