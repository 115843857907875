/**
 * Converts dynamic NextJS paths (e.g. `/energie-besparen/:solution/:topic`)
 * to the equivalent page file path (`/energie-besparen/[solution]/[topic]`).
 * Note that the file extension is not included
 * @param {string} routePath A path like the source fields of routes.js entries
 * @returns A path to a file like those in the /pages folder
 */
function convertRoutePathToFilePath(routePath) {
  return routePath.replaceAll(/:(.*?)\//g, '[$1]/').replaceAll(/:(.*?)$/g, '[$1]');
}

module.exports = {
  convertRoutePathToFilePath,
};
