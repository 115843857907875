import { gql } from '@apollo/client';

export const LabelRegistrationFragment = gql`
  fragment LabelRegistration on EPOLabel {
    registered
    energyLabel
    energyIndex
    intakeDate
    expirationDate
    calculationType
    buildingType

    energyNeed
    heatingNeed
    primaryFossilEnergy
    primaryFossilEnergyFlat
    renewableEnergyRatio
    renewableEnergyRatioFlat
  }
`;
