import config from '../config';

export function inIFrame(allowUrl = config.isDeveloping || config.isStaging) {
  if (typeof window === 'undefined') return false;
  if (allowUrl && window.location.pathname.includes('/iframe/')) return true;
  try {
    // The first one should work, but since Impact Hypotheken reported issues where it was redirecting to the homepage, the second option offers a fallback.
    return window.self !== window.top || window.location !== window.parent.location;
  } catch (e) {
    return true;
  }
}

export const getIFrameBgColor = (param: string | string[] | undefined) => {
  if (param === undefined) return 'greenSlate';
  try {
    if (Number(param) === 1) return 'white';
    if (Number(param) === 2) return 'transparent';
    if (Number(param) === 3) return 'goldSlate';
    return 'greenSlate';
  } catch {
    return 'greenSlate';
  }
};
