import { Button, Flex } from '@energiebespaarders/symbols';
import HybridLink from '../HybridLink';

interface Props {
  showContactForm: () => void;
  handleClose: () => void;
}

const PreContactModalContent: React.FC<Props> = ({ showContactForm, handleClose }) => {
  return (
    <Flex flexDirection="column" mb={2}>
      <Flex mb={2} flexDirection="column">
        <p style={{ marginBottom: 12 }}>
          Heb je een vraag over je installatie of over een specifiek product? Veel antwoorden vind
          je gemakkelijk zelf in onze veelgestelde vragen.
        </p>
        <p>Kom je er niet uit? Neem dan direct contact met ons op. We helpen je graag verder. </p>
      </Flex>
      <Flex flexDirection="row">
        <Button onClick={showContactForm} mr={3} inverse label="Contact opnemen" />
        <HybridLink to="/faq" onClick={handleClose}>
          <Button label="Bekijk de veelgestelde vragen" />
        </HybridLink>
      </Flex>
    </Flex>
  );
};

export default PreContactModalContent;
