import { gql, useMutation } from '@apollo/client';
import useForm, { isInvalidEmail, isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Flex, Input } from '@energiebespaarders/symbols';
import { CaretLeft } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type {
  forgotCustomerPassword,
  forgotCustomerPasswordVariables,
} from '../../types/generated/forgotCustomerPassword';

const FORGOT_CUSTOMER_PASSWORD = gql`
  mutation forgotCustomerPassword($email: String!) {
    forgotCustomerPassword(email: $email) {
      error
      success
    }
  }
`;

type ForgotPasswordFormProps = {
  cancel: () => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ cancel, email, setEmail }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const [requestPasswordReset, { loading, error }] = useMutation<
    forgotCustomerPassword,
    forgotCustomerPasswordVariables
  >(FORGOT_CUSTOMER_PASSWORD);

  const { formState, handleChange, submitForm } = useForm<{ email: string }>({
    initialValues: {
      email,
    },
    handleSubmit: ({ email }) =>
      requestPasswordReset({
        variables: {
          email: email.toLowerCase(),
        },
      }).then(() => setShowConfirm(true)),
    validate: ({ email }, errors) => {
      if (isMissing(email)) {
        errors.email = 'Ontbreekt';
      }
      if (isInvalidEmail(email)) {
        errors.email = 'Ongeldig e-mailadres';
      }
      return errors;
    },
  });

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    handleChange({ email: e.target.value });
  };

  return !showConfirm ? (
    <form onSubmit={submitForm} style={{ width: '100%' }}>
      <Flex flexWrap="wrap" mx={-1}>
        <Box width={[1, 3 / 5]} px={1}>
          <Input
            type="email"
            label="E-mailadres"
            onChange={handleChangeEmail}
            value={formState.email.value}
            touched={formState.email.touched}
            error={formState.email.error}
            autoComplete="email"
            inputMode="email"
          />
        </Box>
        <Box width={[1, 2 / 5]} px={1} alignSelf="flex-end">
          <Button
            fluid
            type="submit"
            disabled={!formState.email.value}
            loading={loading}
            bgColor="yellow"
            color="grayBlack"
            error={error}
            label="Stuur me een herstellink →"
            style={{ borderRadius: 3 }}
          />
        </Box>
        <Box width={1} px={1}>
          <Button
            onClick={cancel}
            minimal
            bgColor="red"
            label="Annuleren"
            iconStart={CaretLeft}
            px={5}
            style={{ borderRadius: 3 }}
          />
        </Box>
      </Flex>
    </form>
  ) : (
    <ForgotPasswordConfirmation email={email} />
  );
};

export default ForgotPasswordForm;
