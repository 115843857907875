import { gql } from '@apollo/client';
import { BasicAddressFragment } from './../fragments/House';

export const LOGOUT_PARTNER_AGENT = gql`
  mutation logoutPartnerAgent {
    logoutPartnerAgent
  }
`;

export const GET_DOSSIER_BY_PARTNER_AGENT_AND_HOUSE = gql`
  ${BasicAddressFragment}
  query getDossierByPartnerAgentAndHouse($partnerAgentId: ID!, $houseId: ID!) {
    dossierByPartnerAgentAndHouse(partnerAgentId: $partnerAgentId, houseId: $houseId) {
      id
      customerFirstName
      customerLastName
      mortgageLender
      collective {
        id
        key
        name
        logo
      }
      house {
        id
        isAdaxioHouse
        isTriodosHouse
        initialQuestionsAnswered
        ...BasicAddress
        type
        requestedDuties {
          name
          requestedOn
        }
      }
    }
  }
`;

export const REQUEST_DUTY_PACKAGE_AS_PARTNER_AGENT = gql`
  mutation requestDutyPackageAsPartnerAgent(
    $partnerAgentId: ID!
    $dossierId: ID!
    $mortgageLender: PartnerMortgageLender
    $collective: PartnerCollectiveKey
    $email: String!
    $gender: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $appointment: AppointmentInput!
    $motivation: String
    $consent: [ConsentInput!]
    $dutyPackage: String!
    $extraDuties: [DutyName!]
    $notes: String
    $voucherCode: String
    $hasGreenMortgage: Boolean
    $loanId: String
  ) {
    requestDutyPackageAsPartnerAgent(
      partnerAgentId: $partnerAgentId
      dossierId: $dossierId
      # TODO: Rename to mortgageLender after deprecation cycle
      mortgageLender: $mortgageLender
      collective: $collective
      email: $email
      gender: $gender
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      appointment: $appointment
      motivation: $motivation
      consent: $consent
      dutyPackage: $dutyPackage
      extraDuties: $extraDuties
      notes: $notes
      voucherCode: $voucherCode
      hasGreenMortgage: $hasGreenMortgage
      loanId: $loanId
    ) {
      orderId
      constructionYear
      type
    }
  }
`;

export const GET_VELDSINK_OFFICES = gql`
  query getVeldsinkOffices {
    veldsinkOffices {
      id
      name
    }
  }
`;

export const GET_HYPOTHEEK_VISIE_OFFICES = gql`
  query getHypotheekVisieOffices {
    hypotheekVisieOffices {
      id
      name
    }
  }
`;
