import { gql } from '@apollo/client';

export const EnergyDeltaFragment = gql`
  fragment EnergyDelta on EnergyDelta {
    electricityConsumption
    electricityProduction
    gasConsumption
    gasFactor
    emission
    money
    energyIndex
  }
`;

export default EnergyDeltaFragment;
