import { gql } from '@apollo/client';

export const UTMFragment = gql`
  fragment UTM on Utm {
    source
    medium
    campaign
    term
    content
  }
`;
