import { gql, PureQueryOptions, useLazyQuery, useMutation } from '@apollo/client';
import { constants } from '@energiebespaarders/constants';
import _, { isEqual, isUndefined } from 'lodash';
import { useRouter } from 'next/router';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getUserlessEnergyPrices,
  getUserlessEnergyPricesVariables,
} from '~/types/generated/getUserlessEnergyPrices';
import { AtLeastOne } from '../typeHelpers';
import {
  getPersonalEnergyPrices,
  getPersonalEnergyPricesVariables,
} from '../types/generated/getPersonalEnergyPrices';
import {
  updateEnergyPrices,
  updateEnergyPricesVariables,
} from '../types/generated/updateEnergyPrices';
import { useActiveHouseId } from './useActiveHouseId';

const PERSONAL_ENERGY_PRICES = gql`
  query getPersonalEnergyPrices($houseId: ID!) {
    personalEnergyPrices(houseId: $houseId) {
      gas
      electricity
      discountedElectricity
    }
  }
`;

export const UPDATE_ENERGY_PRICES = gql`
  mutation updateEnergyPrices($houseId: ID!, $energyPrices: EnergyPricesInput!) {
    updateEnergyPrices(houseId: $houseId, energyPrices: $energyPrices) {
      gas
      electricity
      discountedElectricity
    }
  }
`;

export const UPDATE_USERLESS_ENERGY_PRICES = gql`
  mutation updateEnergyPrices_Userless($houseId: ID!, $energyPrices: EnergyPricesInput!) {
    updateEnergyPrices_Userless(houseId: $houseId, energyPrices: $energyPrices) {
      gas
      electricity
      discountedElectricity
    }
  }
`;

const USERLESS_ENERGY_PRICES = gql`
  query getUserlessEnergyPrices($houseId: ID!) {
    getUserlessEnergyPrices(houseId: $houseId) {
      gas
      electricity
      discountedElectricity
    }
  }
`;

export type EnergyPrices = { gas: number; electricity: number; discountedElectricity: number };
type ContextValue = [EnergyPrices, Dispatch<SetStateAction<EnergyPrices>> | null];

const initialEnergyPrices: EnergyPrices = {
  gas: constants.energyPrices.gas,
  electricity: constants.energyPrices.electricity,
  discountedElectricity: constants.energyPrices.discountedElectricity,
};

export const PersonalEnergyPricesContext = React.createContext<ContextValue>([
  initialEnergyPrices,
  null,
]);

export const PersonalEnergyPricesProvider: React.FC = ({ children }) => {
  const localEntry =
    typeof window !== 'undefined' ? window?.localStorage?.getItem('energyPrices') : undefined;
  const defaults = localEntry ? JSON.parse(localEntry) : initialEnergyPrices;

  const [energyPrices, setEnergyPrices] = useState(defaults);
  const contextValue: ContextValue = [energyPrices, setEnergyPrices];
  return (
    <PersonalEnergyPricesContext.Provider value={contextValue}>
      {children}
    </PersonalEnergyPricesContext.Provider>
  );
};

export function usePersonalEnergyPrices(
  useUserlessVersion?: boolean,
  queryToRefetch?: PureQueryOptions,
) {
  const router = useRouter();
  const { houseId } = router.query as { houseId?: string }; // Used in Partner Bespaarcheck (Samen)

  const [energyPrices, setEnergyPrices] = useContext(PersonalEnergyPricesContext);

  const { activeHouseId } = useActiveHouseId();

  const [fetchMyEnergyPrices, { data, loading, error }] = useLazyQuery<
    getPersonalEnergyPrices,
    getPersonalEnergyPricesVariables
  >(PERSONAL_ENERGY_PRICES, {
    variables: { houseId: houseId || activeHouseId },
    fetchPolicy: 'cache-and-network',
  });

  const [updateEnergyPrices] = useMutation<updateEnergyPrices, updateEnergyPricesVariables>(
    UPDATE_ENERGY_PRICES,
    { refetchQueries: queryToRefetch ? [queryToRefetch] : [] },
  );

  const [
    fetchUserlessEnergyPrices,
    { data: userlessData, loading: userlessLoading, error: userlessError },
  ] = useLazyQuery<getUserlessEnergyPrices, getUserlessEnergyPricesVariables>(
    USERLESS_ENERGY_PRICES,
    {
      fetchPolicy: 'network-only',
      variables: { houseId: houseId || activeHouseId },
    },
  );

  const [updateUserlessEnergyPrices] = useMutation<updateEnergyPrices, updateEnergyPricesVariables>(
    UPDATE_USERLESS_ENERGY_PRICES,
    { refetchQueries: queryToRefetch ? [queryToRefetch] : [] },
  );

  useEffect(() => {
    if (
      data ||
      loading ||
      error ||
      userlessData ||
      userlessLoading ||
      userlessError ||
      !router.isReady ||
      !(houseId || activeHouseId)
    ) {
      return;
    }

    void (useUserlessVersion ? fetchUserlessEnergyPrices() : fetchMyEnergyPrices());
  }, [
    activeHouseId,
    data,
    error,
    fetchMyEnergyPrices,
    fetchUserlessEnergyPrices,
    loading,
    useUserlessVersion,
    userlessData,
    userlessError,
    userlessLoading,
    router.isReady,
    houseId,
  ]);

  const usingDefaultPrices = useMemo(
    () =>
      energyPrices.gas === initialEnergyPrices.gas &&
      energyPrices.electricity === initialEnergyPrices.electricity &&
      energyPrices.discountedElectricity === _.round(initialEnergyPrices.discountedElectricity, 2),
    [energyPrices],
  );

  useEffect(() => {
    // Initialize the context with currently stored prices for active house
    if (!data) return;
    const storedPrices = data.personalEnergyPrices;

    if (
      storedPrices.gas &&
      storedPrices.electricity &&
      storedPrices.discountedElectricity &&
      !isEqual(storedPrices, energyPrices)
    ) {
      setEnergyPrices?.(storedPrices as EnergyPrices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEnergyPrices, data]);

  const handleSetEnergyPrices = useCallback(
    ({ gas, electricity, discountedElectricity }: AtLeastOne<EnergyPrices>) => {
      const newPrices = {
        gas: _.round(!isUndefined(gas) ? gas : energyPrices.gas, 2),
        electricity: _.round(!isUndefined(electricity) ? electricity : energyPrices.electricity, 2),
        discountedElectricity: _.round(
          !isUndefined(discountedElectricity)
            ? discountedElectricity
            : energyPrices.discountedElectricity,
          2,
        ),
      };

      if (useUserlessVersion) {
        void updateUserlessEnergyPrices({
          variables: { houseId: houseId || activeHouseId, energyPrices: newPrices },
        }).then(res => setEnergyPrices?.(res.data?.updateEnergyPrices ?? newPrices));
      } else {
        void updateEnergyPrices({
          variables: { houseId: houseId || activeHouseId, energyPrices: newPrices },
        }).then(res => setEnergyPrices?.(res.data?.updateEnergyPrices ?? newPrices));
      }

      // Store locally for easy access from utils and reinitialisation
      if (!useUserlessVersion) {
        window?.localStorage.setItem('energyPrices', JSON.stringify(newPrices));
      }
    },
    [
      energyPrices,
      useUserlessVersion,
      updateUserlessEnergyPrices,
      houseId,
      activeHouseId,
      setEnergyPrices,
      updateEnergyPrices,
    ],
  );

  const resetPrices = useCallback(() => {
    setEnergyPrices?.(initialEnergyPrices);
    window?.localStorage.removeItem('energyPrices');
    return initialEnergyPrices;
  }, [setEnergyPrices]);

  return {
    personalEnergyPrices: energyPrices,
    setPersonalEnergyPrices: handleSetEnergyPrices,
    resetPrices,
    defaultPrices: initialEnergyPrices,
    usingDefaultPrices,
  };
}
