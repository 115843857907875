import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import type { FormAddress } from '~/components/AddressFields';
import { GET_SUFFIXES } from '~/queries/house';
import type { getSuffixes, getSuffixesVariables } from '~/types/generated/getSuffixes';
import type { FormState } from '@energiebespaarders/hooks/useForm';
import type { ApolloError } from '@apollo/client';

const useSuffixes = (
  addressFormState: FormState<FormAddress>,
  onError?: (error: ApolloError) => void,
) => {
  const zip = addressFormState.zip.value;
  const number = addressFormState.number.value ?? 0;

  const [fetchSuffixes, { data, loading }] = useLazyQuery<getSuffixes, getSuffixesVariables>(
    GET_SUFFIXES,
    {
      fetchPolicy: 'network-only',
      onError,
    },
  );

  useEffect(() => {
    if (zip && zip.length === 6 && number) {
      void fetchSuffixes({ variables: { address: { zip, number } } });
    }
  }, [zip, number, fetchSuffixes]);

  const suffixes: string[] = useMemo(() => [...(data?.getSuffixes?.suffixes || [])], [data]);

  return { suffixes, loading };
};

export default useSuffixes;
