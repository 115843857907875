import { gql } from '@apollo/client';
import FileFragment from '../fragments/File';
import EnergyDeltaFragment from './EnergyDelta';
import ProductFragment from './Product';
import DiscountFragment from './Discount';

export const QuoteItemFragment = gql`
  ${ProductFragment}
  ${FileFragment}
  fragment QuoteItem on InstallationItem {
    amount
    retailPrice
    product {
      ...Product
      files {
        ...File
      }
    }
  }
`;

export const QuoteFragment = gql`
  ${DiscountFragment}
  ${EnergyDeltaFragment}
  ${QuoteItemFragment}
  ${FileFragment}
  fragment Quote on Quote {
    id
    acceptedOn
    adviserName
    comfortScore
    created
    expirationDate
    final
    investment
    isAccepted
    isAdvised
    isCancelled
    isExpired
    isSeen
    isSent
    operatorName
    efficiency
    paybackTime
    reference
    sentOn
    solution
    subsidy
    text
    hasCompletedIntake
    acceptedSubjectToFunding {
      date
    }
    acceptedSiblingInDeal {
      id
    }
    items {
      ...QuoteItem
    }
    discount {
      ...Discount
    }
    energyDelta {
      ...EnergyDelta
    }
    files {
      ...File
    }
  }
`;

export default QuoteFragment;
