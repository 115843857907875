import { useRouter } from 'next/router';
import { RouteProps } from '~/types/next';
import { routes } from 'routes';
import { convertRoutePathToFilePath } from '../scripts/routeUtils';

/**
 * Lookup table of `convertRoutePathToFilePath(router.pathname)` to its route props
 * Example:
 * - `router.pathname = /Solutions/[solution]/[topic]`
 * - `convertRoutePathToFilePath(router.pathname) = /Solutions/:solution/:topic`
 * - returns `routeProps = { source: '/energie-besparen/:solution/:topic}`
 * Note: Doesn't work with dynamic routes like /MyQuotes/:quoteId that can't have routes.js entries for each ID!
 */
export const RoutesByFilePath = new Map<string, RouteProps>();

for (const route of routes) {
  RoutesByFilePath.set(convertRoutePathToFilePath(route.destination), route);
}

const useRouteProps = () => {
  // Pathname here is the English page filename
  const { pathname } = useRouter();

  const pathnameRouteProps = RoutesByFilePath.get(pathname);

  return pathnameRouteProps;
};

export default useRouteProps;
