import { gql } from '@apollo/client';

export const SolutionAdviceFragment = gql`
  fragment SolutionAdvice on SolutionAdvice {
    advisedQuoteIds
    text
  }
`;

export const AdviceTextsFragment = gql`
  fragment AdviceTexts on Advice {
    wallInsulation {
      text
    }
    floorInsulation {
      text
    }
    roofInsulation {
      text
    }
    insulationGlazing {
      text
    }
    pvSystem {
      text
    }
    heating {
      text
    }
    miscellaneous {
      text
    }
    summary
  }
`;

// UNUSED IN CHILI
export const OperatorFragment = gql`
  fragment Operator on Operator {
    id
    firstName
    lastName
    jobTitle
    phone
    email
  }
`;

export const AdvisedQuotesFragment = gql`
  fragment AdvisedQuotes on Advice {
    wallInsulation {
      advisedQuoteIds
    }
    floorInsulation {
      advisedQuoteIds
    }
    roofInsulation {
      advisedQuoteIds
    }
    insulationGlazing {
      advisedQuoteIds
    }
    pvSystem {
      advisedQuoteIds
    }
    heating {
      advisedQuoteIds
    }
  }
`;

export const AdviceFragment = gql`
  ${SolutionAdviceFragment}
  fragment Advice on Advice {
    id
    authorName
    appendices
    isOpened
    isPaid
    isRequested
    isSent
    sentOn
    solutionDomainOrder
    summary
    wallInsulation {
      ...SolutionAdvice
    }
    floorInsulation {
      ...SolutionAdvice
    }
    roofInsulation {
      ...SolutionAdvice
    }
    insulationGlazing {
      ...SolutionAdvice
    }
    pvSystem {
      ...SolutionAdvice
    }
    heating {
      ...SolutionAdvice
    }
    miscellaneous {
      ...SolutionAdvice
    }
  }
`;

export default AdviceFragment;
