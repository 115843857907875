import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SiteLocale } from '../types/graphql-cms-global-types';

type ContextValue = [SiteLocale | undefined, Dispatch<SetStateAction<SiteLocale>> | undefined];

export const LocaleContext = React.createContext<ContextValue>([undefined, undefined]);

export const LocaleProvider: React.FC<{ initialValue: SiteLocale }> = ({
  children,
  initialValue,
}) => {
  const [locale, setLocale] = useState<SiteLocale>(initialValue);
  const contextValue: ContextValue = [locale, setLocale];
  return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
};

export function useLocale() {
  const [locale, setLocale] = useContext(LocaleContext);
  return { locale: locale!, setLocale: setLocale! };
}
