/** Validates phone number for E.164 format
 * @see https://www.twilio.com/docs/glossary/what-e164
 */
export function validatePhoneForE164(phoneNumber: string) {
  const regEx = /^\+[1-9]\d{10,14}$/;
  return regEx.test(phoneNumber);
}

/** Formats phone number to contain a country code (E.164)
 * This is required for Intercom to recognize the phone number, but also good practice to ensure the phone number is in a consistent format for other services.
 * @see https://www.twilio.com/docs/glossary/what-e164
 * @param phone Phone number to format
 * @param countryCode Country code to use, defaults to 31 (NL)
 * @returns Formatted phone number (string)
 */
export const formatPhoneE164 = (
  phoneNumber?: number | string | null,
  countryCode = 31,
): string | undefined => {
  if (!phoneNumber) return undefined;

  const phoneString = phoneNumber.toString().replaceAll(' ', '');
  if (validatePhoneForE164(phoneString)) return phoneString;

  if (phoneString.length !== 10) console.warn('Phone number is not 10 digits long');
  if (!phoneString.startsWith('0')) console.warn("Phone number doesn't start with a 0");

  // Return formatted phone number with country code by removing a leading 0 and adding the country code with a +
  // Limit to 15 digits after the +
  return phoneString.replace(/^0/, `+${countryCode}`).substring(0, 16);
};
