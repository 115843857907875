import dynamic from 'next/dynamic';
import React from 'react';
import ErrorBoundary from '~/components/ErrorBoundary';
import type { RouteProps } from '~/types/next';
import type { ReactNode } from 'react';

const Footer = dynamic(() => import('~/components/_app/Footer'));
const Navigation = dynamic(() => import('../Navigation'));
const BackgroundWrapper = dynamic(() => import('~/components/BackgroundWrapper'));

const NavAndFooter: React.FC<{
  hideNav?: boolean;
  hideFooter?: boolean;
  useGradientBg?: boolean;
}> = props => {
  return (
    <ErrorBoundary>
      {!props.hideNav && <Navigation />}
      {props.useGradientBg ? (
        <BackgroundWrapper>{props.children}</BackgroundWrapper>
      ) : (
        props.children
      )}
      {!props.hideFooter && <Footer />}
    </ErrorBoundary>
  );
};

export const getNavAndFooterLayout = (
  page: ReactNode,
  pageProps: Record<string, unknown>,
  routeProps?: RouteProps,
): ReactNode => {
  const showNavAndFooter = {
    hideNav: pageProps.hideNav as boolean | undefined,
    hideFooter: pageProps.hideFooter as boolean | undefined,
    useGradientBg: pageProps.useGradientBg as boolean | undefined,
  };

  return (
    <NavAndFooter {...showNavAndFooter} {...routeProps}>
      {page}
    </NavAndFooter>
  );
};

export default NavAndFooter;
