import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  Select,
  TextLink,
  Textarea,
} from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { PaperAirplane } from '@energiebespaarders/symbols/icons/solid';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import type { ContactModalContent } from '~/hooks/useContactModal';
import { useMeOptional } from '~/hooks/useMe';
import useSuffixes from '~/hooks/useSuffixes';
import type { me_me_Customer } from '~/types/generated/me';
import type { sendContactMail, sendContactMailVariables } from '~/types/generated/sendContactMail';
import type { ContactCategory, ContactSubject } from '~/types/graphql-global-types';
import { categoryOptions, contactSubjects } from '~/utils/constants';
import AddressFields from './AddressFields';
import PreContactModalContent from './_app/PreContactModalContent';
import type { ChangeEvent } from 'react';

export const SEND_CONTACT_MAIL = gql`
  mutation sendContactMail(
    $category: ContactCategory!
    $customer: CustomerEmailInput!
    $pathname: String!
    $message: String!
    $subject: ContactSubject!
  ) {
    contact(
      category: $category
      customer: $customer
      pathname: $pathname
      message: $message
      subject: $subject
    )
  }
`;

// Fallback contact info in case all else fails
export const ContactInfoFallback = () => {
  const intercom = useIntercom();
  return (
    <p>
      Je kunt ons ook een berichtje sturen{' '}
      <TextLink onClick={() => intercom.showNewMessage()} color="green">
        via de chat.
      </TextLink>
    </p>
  );
};

interface IContactFormProps {
  customer?: me_me_Customer;
  pathname?: string;
  text?: string;
  preset?: ContactFormPreset;
}

export type ContactFormPreset = Partial<
  Pick<ContactFormValues, 'category' | 'subject' | 'message'>
>;

interface ContactFormValues {
  category: ContactCategory;
  subject: ContactSubject;
  message: string;
  customerId: string;
  customer: {
    gender: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  address: {
    zip: string;
    number: number;
    suffix: string;
  };
}

const ContactForm: React.FC<IContactFormProps> = ({ customer, pathname, text, preset }) => {
  const router = useRouter();
  const [messageSent, setMessageSent] = useState(false);

  const [sendContactMailMutation, { loading, error }] = useMutation<
    sendContactMail,
    sendContactMailVariables
  >(SEND_CONTACT_MAIL, {
    onCompleted: data => data?.contact && setMessageSent(true),
  });

  const { formState, handleChange, submitForm } = useForm<ContactFormValues>({
    initialValues: {
      category: preset?.category || '',
      subject: preset?.subject || '',
      message: preset?.message || '',
      customerId: customer?.id || '',
      customer: {
        gender: customer?.gender || '',
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        email: customer?.email || '',
        phone: customer?.phone || '',
      },
      address: {
        zip: customer?.address?.zip || '',
        number: customer?.address?.number || 0,
        suffix: customer?.address?.suffix || '',
      },
    },
    blockEnterToSubmit: true,
    handleSubmit: values =>
      sendContactMailMutation({
        variables: {
          customer: {
            id: values.customerId,
            gender: values.customer.gender,
            firstName: values.customer.firstName,
            lastName: values.customer.lastName,
            email: values.customer.email,
            phone: values.customer.phone,
            address: values.address,
          },
          category: formState.category.value,
          subject: formState.subject.value,
          message: formState.message.value,
          pathname: pathname || router.asPath,
        },
      }),
    validate: (values, errors) => {
      if (!values.category) errors.category = 'Ontbreekt';
      if (!values.subject) errors.subject = 'Ontbreekt';
      if (!values.message) errors.message = 'Typ een bericht';
      if (!values.customer.firstName) errors.customer.firstName = 'Ontbreekt';
      if (!values.customer.lastName) errors.customer.lastName = 'Ontbreekt';
      if (!values.customer.email) errors.customer.email = 'Ontbreekt';
      return errors;
    },
  });

  const { suffixes, loading: suffixesLoading } = useSuffixes(formState.address);

  const subjectOptions = useMemo(
    () => (formState.category.value ? contactSubjects[formState.category.value] : []),
    [formState.category.value],
  );

  const subjectLabel = useMemo(() => {
    if (!formState.category.value) return 'Onderwerp';
    switch (formState.category.value) {
      case 'question':
        return `Waarover gaat ${customer ? 'uw' : 'je'} vraag?`;
      case 'complaint':
        return `Waar gaat ${customer ? 'uw' : 'je'} klacht over?`;
      case 'technicalIssue':
        return `Waarmee ondervindt ${customer ? 'u' : 'je'} het probleem?`;
      case 'feedback':
        return 'Wat voor feedback is het?';
      default:
        return 'Onderwerp';
    }
  }, [formState.category.value, customer]);

  const textAreaPlaceholder = useMemo(() => {
    if (!formState.category.value) return 'Kies een categorie';
    if (!formState.subject.value) return 'Kies een onderwerp';
    switch (formState.category.value) {
      case 'question':
        return `Stel ${customer ? 'uw' : 'je'} vraag`;
      case 'complaint':
        return `Waar gaat ${customer ? 'uw' : 'je'} klacht over?`;
      case 'technicalIssue':
        return `Omschrijf het probleem dat ${customer ? 'u' : 'je'} ervaart`;
      case 'feedback':
        return `We horen graag wat ${customer ? 'uw' : 'je'} feedback is!`;
      default:
        return 'Kies een categorie';
    }
  }, [formState.category.value, formState.subject.value, customer]);

  return (
    <>
      <p>{text}</p>
      {messageSent ? (
        <div>
          <strong>
            Bedankt! Uw bericht is goed ontvangen en wordt zo spoedig mogelijk opgepakt.
          </strong>
        </div>
      ) : (
        <form onSubmit={submitForm}>
          <Flex flexWrap="wrap">
            {!customer && (
              <>
                <Box width={1 / 2} px="3px">
                  <Input
                    label="Voornaam*"
                    placeholder="Voornaam"
                    type="text"
                    required
                    value={formState.customer.firstName.value}
                    error={formState.customer.firstName.error}
                    touched={formState.customer.firstName.touched}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange({ customer: { firstName: e.target.value } })
                    }
                    autoComplete="given-name"
                  />
                </Box>
                <Box width={1 / 2} px="3px">
                  <Input
                    label="Achternaam*"
                    placeholder="Achternaam"
                    type="text"
                    required
                    value={formState.customer.lastName.value}
                    error={formState.customer.lastName.error}
                    touched={formState.customer.lastName.touched}
                    onChange={e => handleChange({ customer: { lastName: e.target.value } })}
                    autoComplete="family-name"
                  />
                </Box>
                <Box width={[1, 1 / 2]} px="3px">
                  <Input
                    label="E-mail*"
                    placeholder="E-mail"
                    type="email"
                    required
                    value={formState.customer.email.value}
                    error={formState.customer.email.error}
                    touched={formState.customer.email.touched}
                    onChange={e => handleChange({ customer: { email: e.target.value } })}
                    autoComplete="email"
                    inputMode="email"
                  />
                </Box>
                <Box width={[1, 1 / 2]} px="3px">
                  <Input
                    label="Telefoonnummer"
                    type="tel"
                    pattern="[0-9]+"
                    value={formState.customer.phone.value}
                    error={formState.customer.phone.error}
                    touched={formState.customer.phone.touched}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange({ customer: { phone: e.target.value } })
                    }
                    autoComplete="tel"
                    inputMode="tel"
                  />
                </Box>

                <Box width={1} px="3px">
                  <AddressFields
                    formState={formState.address}
                    handleChange={address => handleChange({ address })}
                    suffixes={suffixes}
                    suffixesLoading={suffixesLoading}
                    labelColor="grayDark"
                  />
                </Box>
              </>
            )}
            <Box width={1}>
              <Select
                options={categoryOptions}
                label="Categorie"
                labelSize={6}
                onChange={e => {
                  const newCategory = e.value;
                  // TODO: should be doing subject: undefined here, but handleChange with undefined is ignored :/
                  if (formState.subject.value) {
                    handleChange({ subject: contactSubjects[newCategory][0]?.value || '' });
                  }
                  handleChange({ category: newCategory });
                }}
                value={categoryOptions.find(option => option.value === formState.category.value)}
                touched={formState.category.touched}
                error={formState.category.error}
                placeholder="Kies een categorie"
                required
              />
            </Box>
            <Box width={1} mb={1}>
              <Select
                disabled={!formState.category.value}
                options={[
                  { label: 'Kies een onderwerp', value: '', disabled: true },
                  ...subjectOptions,
                ]}
                label={subjectLabel}
                labelSize={6}
                onChange={e => handleChange({ subject: e.value })}
                value={subjectOptions.find(option => option.value === formState.subject.value)}
                touched={formState.subject.touched}
                error={formState.subject.error}
                placeholder="Kies een onderwerp"
                required
              />
            </Box>
            <Box width={1}>
              <Textarea
                disabled={!formState.category.value || !formState.subject.value}
                onChange={e => handleChange({ message: e.target.value })}
                label="Bericht"
                labelSize={6}
                placeholder={textAreaPlaceholder}
                rows={5}
                style={{ width: '100%', resize: 'vertical' }}
                value={formState.message.value}
                touched={formState.message.touched}
                error={formState.message.error}
              />
            </Box>
            <Box width={1} textAlign="right">
              <Button
                type="submit"
                loading={loading}
                error={error && 'Er is iets misgegaan.'}
                mr={0}
                label="Bericht verzenden"
                iconStart={PaperAirplane}
              />
            </Box>
            {error && <ContactInfoFallback />}
          </Flex>
        </form>
      )}
    </>
  );
};

interface IContactModalProps {
  isOpen: boolean;
  closeModal: () => void;
  pathname?: string;
  content: ContactModalContent;
  setContent: React.Dispatch<React.SetStateAction<ContactModalContent>>;
}

const ContactModal = ({
  isOpen,
  pathname,
  closeModal,
  preset,
  content,
  setContent,
}: IContactModalProps & IContactFormProps) => {
  const { me } = useMeOptional();
  const isMobile = useIsMobile();
  const bodyText =
    me?.__typename === 'Customer'
      ? 'Vul hieronder het formulier in. Wij zorgen dat uw vraag of opmerking bij de juiste persoon komt om u zo goed mogelijk te helpen.'
      : 'Vul hieronder het formulier in. Wij zorgen dat jouw vraag of opmerking bij de juiste persoon komt om je zo goed mogelijk te helpen.';
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onRequestClose={closeModal}
      title="Contactformulier"
      mobile={isMobile}
    >
      {content === 'contactForm' ? (
        <ContactForm
          text={bodyText}
          preset={preset}
          pathname={pathname}
          customer={me?.__typename === 'Customer' ? me : undefined}
        />
      ) : (
        <PreContactModalContent
          handleClose={closeModal}
          showContactForm={() => setContent('contactForm')}
        />
      )}
    </Modal>
  );
};

export default ContactModal;
