import { gql } from '@apollo/client';
import { MeCustomerFragment } from '../fragments';

export const LOGOUT_CUSTOMER = gql`
  mutation logoutCustomer {
    logoutCustomer
  }
`;

export const UPDATE_CUSTOMER_PASSWORD = gql`
  mutation updateCustomerPassword($id: ID!, $newPassword: String!) {
    updateCustomerPassword(id: $id, newPassword: $newPassword) {
      success
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  ${MeCustomerFragment}
  mutation updateCustomer($id: ID!, $customer: CustomerInput!) {
    updateCustomer(id: $id, customer: $customer) {
      id
      ...MeCustomer
    }
  }
`;
