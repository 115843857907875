import { tint as polishedTint, shade as polishedShade, rgba, saturate } from 'polished';
import { css } from 'styled-components';
import { getValueFromKey } from '../utils/getValueFromKey';
import theme from './theme';

export function isValidColor(color: string) {
  if (typeof window === 'undefined') return true;
  if (!color) return false;
  const s = new Option().style;
  s.color = color;
  return s.color !== '';
}

export function shade(amount: number, color: string) {
  if (!color || !isValidColor(color)) return '';
  if (isValidColor(color)) {
    return polishedShade(1 - amount, color);
  }
  return color;
}

export function tint(amount: number, color: string) {
  if (!color || !isValidColor(color)) return '';
  if (typeof amount === 'string') {
    if (isValidColor(amount) && isValidColor(amount)) {
      console.warn('tint params have been mixed up!');
      return polishedTint(1, amount);
    }
    return amount;
  }
  if (isValidColor(color)) {
    return polishedTint(1 - amount, color);
  }
  return color;
}

type MixinSide =
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'horizontal'
  | 'vertical'
  | 't'
  | 'b'
  | 'r'
  | 'l'
  | 'h'
  | 'x'
  | 'v'
  | 'y';

export function padding(amount: number | string, side?: MixinSide) {
  let useEms = false;
  if (typeof amount === 'string' && amount.indexOf('em') > -1) {
    // eslint-disable-next-line no-param-reassign
    amount = amount.split('em')[0];
    useEms = true;
  }
  if (typeof amount !== 'number') return;
  const { spacer } = theme;
  if (side === 'top' || side === 't') {
    return css`
      padding-top: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'right' || side === 'r') {
    return css`
      padding-right: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'bottom' || side === 'b') {
    return css`
      padding-bottom: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'left' || side === 'l') {
    return css`
      padding-left: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'horizontal' || side === 'x') {
    return css`
      padding-left: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
      padding-right: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'vertical' || side === 'y') {
    return css`
      padding-top: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
      padding-bottom: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else {
    return css`
      padding: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  }
}

export function margin(amount: number | string, side?: MixinSide) {
  let useEms = false;
  if (typeof amount === 'string' && amount.indexOf('em') > -1) {
    // eslint-disable-next-line no-param-reassign
    amount = amount.split('em')[0];
    useEms = true;
  } else if (amount === 'auto') {
    return css`
      margin: 0 auto;
    `;
  }
  if (typeof amount !== 'number') return;
  const { spacer } = theme;
  if (side === 'top' || side === 't') {
    return css`
      margin-top: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'right' || side === 'r') {
    return css`
      margin-right: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'bottom' || side === 'b') {
    return css`
      margin-bottom: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'left' || side === 'l') {
    return css`
      margin-left: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'horizontal' || side === 'x') {
    return css`
      margin-left: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
      margin-right: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else if (side === 'vertical' || side === 'y') {
    return css`
      margin-top: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
      margin-bottom: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  } else {
    return css`
      margin: ${useEms ? `${amount}em` : `calc(${amount} * ${spacer})`};
    `;
  }
}

export function pos(side: MixinSide, amount: number) {
  const spacer = theme.spacer;
  if (side === 'top' || side === 't') {
    return css`
      top: calc(${amount} * ${spacer});
    `;
  } else if (side === 'right' || side === 'r') {
    return css`
      right: calc(${amount} * ${spacer});
    `;
  } else if (side === 'bottom' || side === 'b') {
    return css`
      bottom: calc(${amount} * ${spacer});
    `;
  } else if (side === 'left' || side === 'l') {
    return css`
      left: calc(${amount} * ${spacer});
    `;
  }
}

export function align(alignment: 'left' | 'right' | 'center' | 'justify') {
  switch (alignment) {
    case 'left':
      return css`
        text-align: left;
        margin-left: 0;
        left: 0;
      `;
    case 'center':
      return css`
        text-align: center;
        margin: 0 auto;
      `;
    case 'right':
      return css`
        text-align: right;
        margin-left: 0;
        right: 0;
      `;
    case 'justify':
      return css`
        text-align: justify;
      `;
    default:
      break;
  }
}

export function themify(color?: string) {
  if (!color) return '';
  if (color.includes('.')) {
    const subColor = getValueFromKey(color, theme.colors);
    if (subColor) return subColor;
  }
  const themeColor = getValueFromKey(color, theme.colors);
  return themeColor || color;
}

export function boxShadow(shadow: 'none' | 'sm' | 'md' | 'lg' | 'xl' = 'none', color = 'green') {
  switch (shadow) {
    case 'xl':
      return css`
        box-shadow:
          0 6px 16px -8px ${rgba(shade(0.8, themify(color)), 0.2)},
          0 6px 12px 0 rgba(0, 0, 0, 0.1);
      `;
    case 'lg':
      return css`
        box-shadow:
          0 4px 12px -4px ${rgba(shade(0.8, themify(color)), 0.2)},
          0 4px 8px 0 rgba(0, 0, 0, 0.1);
      `;
    case 'md':
      return css`
        box-shadow:
          0 2px 6px -2px ${rgba(shade(0.8, themify(color)), 0.2)},
          0 3px 4px 0 rgba(0, 0, 0, 0.1);
      `;
    case 'sm':
      return css`
        box-shadow:
          0 1px 3px -1px ${rgba(shade(0.8, themify(color)), 0.2)},
          0 2px 3px 0 rgba(0, 0, 0, 0.1);
      `;
    case 'none':
      return css`
        box-shadow: none;
      `;
    default:
      return css`
        box-shadow:
          0 2px 6px -2px ${rgba(shade(0.8, themify(color)), 0.2)},
          0 3px 4px 0 rgba(0, 0, 0, 0.1);
      `;
  }
}

export function highContrast(color: string) {
  const themeColor = getValueFromKey(color, theme.colors);
  if (themeColor) {
    return saturate(100, themeColor);
  }
  return color;
}

export function targetIE(version: number) {
  if (version >= 10) {
    return (rules: TemplateStringsArray) => css`
      @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        ${css(rules)};
      }
    `;
  } else if (version === 6 || version === 7 || version === 8) {
    return (rules: TemplateStringsArray) => css`
      @media screen\9 {
        ${css(rules)};
      }
    `;
  } else if (version === 8 || version === 9) {
    return (rules: TemplateStringsArray) => css`
      @media screen\0 {
        ${css(rules)};
      }
    `;
  } else if (!version) {
    return (rules: TemplateStringsArray) => css`
      @media screen\9 {
        ${css(rules)};
      }

      @media screen\0 {
        ${css(rules)};
      }

      @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        ${css(rules)};
      }
    `;
  }
}

const mixins = {
  shade,
  tint,
  padding,
  margin,
  align,
  isValidColor,
  themify,
  targetIE,
};

export default mixins;
