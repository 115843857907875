import { gql } from '@apollo/client';

export const FileFragment = gql`
  fragment File on File {
    id
    extension
    metadata {
      title
      tags
      fileSize
    }
  }
`;

export default FileFragment;
