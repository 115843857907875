import { gql } from '@apollo/client';
import {
  BasicAddressFragment,
  DetailsFragment,
  HouseEnergyFragment,
  SituationEnergyPricesFragment,
} from '../fragments';

export const INITIALIZE_USER_HOUSE = gql`
  ${BasicAddressFragment}
  ${DetailsFragment}
  mutation initializeUserHouse(
    $userId: String!
    $userType: String!
    $address: AddressInput!
    $journeyData: JourneyEventInput
  ) {
    initializeUserHouse(
      userId: $userId
      userType: $userType
      address: $address
      journeyData: $journeyData
    ) {
      id
      ...BasicAddress
      ...Details
    }
  }
`;

export const GET_SUFFIXES = gql`
  query getSuffixes($address: BasicAddressInput!) {
    getSuffixes(address: $address) {
      suffixes
    }
  }
`;

export const FIND_ADDRESS_FUNCTION = gql`
  query findAddressFunction($address: BasicAddressInput!) {
    findAddressFunction(address: $address)
  }
`;

export const ADDRESS_CARD = gql`
  ${BasicAddressFragment}
  query addressCard($id: ID!) {
    house(id: $id) {
      id
      area
      vve
      ...BasicAddress
      constructionYear
      type
      initialQuestionsAnswered
      labelRegistration {
        registered
        energyLabel
      }
      requestedDuties {
        name
      }
    }
  }
`;

export const ADVICE_STATUS = gql`
  query getAdviceStatus($houseId: ID!) {
    house(id: $houseId) {
      id
      requestedDuties {
        name
      }
      progress {
        id
        duties {
          adviceReport {
            requested
          }
          visit {
            requested
            plannedFor
            done
          }
          virtualVisit {
            requested
            plannedFor
            done
          }
          energyLabel {
            registered
          }
        }
      }
      advice {
        id
        isRequested
        isPaid
        isRequested
        isSent
        order {
          id
        }
      }
    }
  }
`;

export const LEAD_HOUSE_BY_ID = gql`
  ${BasicAddressFragment}
  query leadHouseById($id: ID!) {
    house(id: $id) {
      id
      ...BasicAddress
      constructionYear
      type
      created
      monument
      vve
    }
  }
`;

export const GET_HOUSE_ZIP = gql`
  query getHouseZip($id: ID!) {
    house(id: $id) {
      id
      address {
        zip
      }
    }
  }
`;

export const HOUSE_CONSUMPTION = gql`
  ${SituationEnergyPricesFragment}
  query getHouseConsumption($id: ID!) {
    house(id: $id) {
      id
      energy {
        electricityConsumption
        electricityProduction
        gasConsumption
        energyIndex
      }
      situation {
        id
        consumption {
          gas
          electricity
        }
        energyPrices {
          ...SituationEnergyPrices
        }
        slurperConsumption
        remainingGasPercentage
        solar {
          currentSystem {
            energyYieldPercentage
          }
        }
      }
    }
  }
`;

export const START_USERLESS_SAVINGS_CHECK = gql`
  mutation startUserlessSavingsCheck(
    $address: AddressInput!
    $utm: UtmInput
    $referrer: String
    $pageViews: [String!]!
  ) {
    startUserlessSavingsCheck(
      address: $address
      utm: $utm
      referrer: $referrer
      pageViews: $pageViews
    ) {
      id
    }
  }
`;

export const USERLESS_HOUSE_CONSUMPTION = gql`
  query getUserlessHouseConsumption($id: ID!) {
    findUserlessHouse(houseId: $id) {
      id
      energy {
        electricityConsumption
        electricityProduction
        gasConsumption
        energyIndex
      }
      situation {
        slurperConsumption
        consumption {
          gas
          electricity
        }
      }
    }
  }
`;

export const GET_USERLESS_HOUSE = gql`
  ${HouseEnergyFragment}
  query getUserlessHouse($houseId: ID!) {
    findUserlessHouse(houseId: $houseId) {
      id
      address {
        zip
        street
        number
        suffix
        city
      }
      area
      constructionYear
      energyLabel
      energy {
        ...HouseEnergy
      }
      residents
      type
      vve
      woz
      stories
      monument
      pvSystemPower
      labelRegistration {
        energyLabel
      }
      crawlspace {
        compartments
      }
      walls {
        cavity
        rc
        area
      }
      floor {
        rc
        area
      }
      roof {
        rc
        area
      }
      windowsZTAU {
        livingU
        sleepingU
        totalSingleArea
        totalDoubleArea
        totalHRArea
      }
      heating {
        boilerAge
      }
      situation {
        solar {
          currentSystem {
            panels
          }
        }
        currentOccupant
        consumption {
          gas
          electricity
        }
      }
    }
  }
`;
