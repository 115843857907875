import type { ParsedUrlQuery } from 'querystring';

export interface UTM {
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  content?: string | null;
  term?: string | null;
}

type UTMParameter = keyof UTM;

// Collect and remove the UTM parameters from the URL
export function parseUtm(query: ParsedUrlQuery) {
  const utm: UTM = {};
  const singleValue = (value: string | string[]) => {
    if (Array.isArray(value)) return value[value.length - 1];
    return value;
  };

  if (query.utm_source) utm.source = singleValue(query.utm_source);
  if (query.utm_medium) utm.medium = singleValue(query.utm_medium);
  if (query.utm_campaign) utm.campaign = singleValue(query.utm_campaign);
  if (query.utm_content) utm.content = singleValue(query.utm_content);
  if (query.utm_term) utm.term = singleValue(query.utm_term);

  const queryObjWithoutUtm = { ...query };

  delete queryObjWithoutUtm.utm_source;
  delete queryObjWithoutUtm.utm_medium;
  delete queryObjWithoutUtm.utm_campaign;
  delete queryObjWithoutUtm.utm_content;
  delete queryObjWithoutUtm.utm_term;

  const queryWithoutUtm = new URLSearchParams(
    queryObjWithoutUtm as Record<string, string>,
  ).toString();

  // Return UTM parameters in UTM object and new query string without UTM parameters
  return { urlUtm: utm, queryWithoutUtm };
}

export function stringifyUtm(utm?: UTM | null) {
  if (!utm) return '';
  return new URLSearchParams({
    utm_source: utm?.source || '',
    utm_medium: utm?.medium || '',
    utm_campaign: utm?.campaign || '',
    utm_content: utm?.content || '',
    utm_term: utm?.term || '',
  }).toString();
}

export function isUtmEmpty(utm?: UTM | null) {
  if (!utm) return true;
  const utmParameters: UTMParameter[] = ['source', 'medium', 'campaign', 'term', 'content'];
  return (
    Object.keys(utm).length === 0 ||
    Object.keys(utm)
      .filter(x => utmParameters.indexOf(x as UTMParameter) > -1)
      .every(param => utm[param as UTMParameter] === '')
  );
}
