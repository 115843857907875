/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SiteLocale {
  en = "en",
  nl_NL = "nl_NL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
