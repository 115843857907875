import { gql } from '@apollo/client';
import { ConsumptionFragment } from './Consumption';

export const SituationEnergyPricesFragment = gql`
  fragment SituationEnergyPrices on EnergyPriceSituation {
    gas
    electricity
    discountedElectricity
    gasNetworkOperator
  }
`;

export const CurrentInsulationFragment = gql`
  fragment CurrentInsulation on CurrentInsulation {
    material
    locations
  }
`;

export const WallSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment WallSituation on WallSituation {
    permissionVVE
    currentInsulation {
      ...CurrentInsulation
    }
    rubbish
  }
`;

export const FloorSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment FloorSituation on FloorSituation {
    permissionVVE
    currentInsulation {
      ...CurrentInsulation
    }
    musty
  }
`;

export const RoofSituationFragment = gql`
  ${CurrentInsulationFragment}
  fragment RoofSituation on RoofSituation {
    permissionVVE
    state
    possibleInsulationLocations
    currentInsulation {
      ...CurrentInsulation
    }
    spareTiles
    atticIsLivingSpace
  }
`;

export const CurrentSystemFragment = gql`
  fragment CurrentSystem on CurrentSystem {
    energyYield
    extensible
    panels
    panelBrand
    panelType
    inverterBrand
  }
`;

export const SolarSituationFragment = gql`
  ${CurrentSystemFragment}
  fragment SolarSituation on SolarSituation {
    permissionVVE
    cableRoute
    inverterLocation
    currentSystem {
      ...CurrentSystem
    }
  }
`;

export const CrawlspaceSituationFragment = gql`
  fragment CrawlspaceSituation on CrawlspaceSituation {
    wetness
    pipework
    rubbish
    obstruction
    separator
    hatchAccessible
    hatchLocation
  }
`;

export const HeatingSituationFragment = gql`
  fragment HeatingSituation on HeatingSituation {
    CW
    boilerBrand
    boilerInstallationYear
    newBoilerLocation
  }
`;

export const ElectricsSituationFragment = gql`
  fragment ElectricsSituation on ElectricsSituation {
    transformerPhases
    transformerInternetAccess
    availablePowerSocket
    availableFuseGroups
  }
`;

export const SituationFragment = gql`
  ${ConsumptionFragment}
  ${WallSituationFragment}
  ${FloorSituationFragment}
  ${CrawlspaceSituationFragment}
  ${RoofSituationFragment}
  ${SolarSituationFragment}
  ${HeatingSituationFragment}
  ${ElectricsSituationFragment}
  ${SituationEnergyPricesFragment}
  fragment Situation on Situation {
    id
    consumption {
      ...Consumption
    }
    energyBill
    energyPrices {
      ...SituationEnergyPrices
    }
    currentOccupant
    currentSlurpers
    walls {
      ...WallSituation
    }
    floor {
      ...FloorSituation
    }
    crawlspace {
      ...CrawlspaceSituation
    }
    roof {
      ...RoofSituation
    }
    solar {
      ...SolarSituation
    }
    heating {
      ...HeatingSituation
    }
    electrics {
      ...ElectricsSituation
    }
  }
`;

export default SituationFragment;
