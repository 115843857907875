// https://github.com/shiena/ansicolor/blob/master/README.md
// Usage: console.log(ansi.red, message, optional args)
// Message will be coloured in red, optional args won't be.
export const ansi = {
  red: '\x1b[31m%s\x1b[0m',
  green: '\x1b[32m%s\x1b[0m',
  yellow: '\x1b[33m%s\x1b[0m',
  blue: '\x1b[34m%s\x1b[0m',
  magenta: '\x1b[35m%s\x1b[0m',
  cyan: '\x1b[36m%s\x1b[0m',
  lightGray: '\x1b[90m%s\x1b[0m',
  lightRed: '\x1b[91m%s\x1b[0m',
  lightGreen: '\x1b[92m%s\x1b[0m',
  lightYellow: '\x1b[93m%s\x1b[0m',
  lightBlue: '\x1b[94m%s\x1b[0m',
  lightMagenta: '\x1b[95m%s\x1b[0m',
  lightCyan: '\x1b[96m%s\x1b[0m',
  lightWhite: '\x1b[97m%s\x1b[0m',
};
