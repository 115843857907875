import { ApolloProvider } from '@apollo/client';
import 'dayjs/locale/nl';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { FlagsProvider } from 'react-unleash-flags';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from '~/components/ErrorBoundary';
import AppHelmet from '~/components/_app/AppHelmet';
import AppInitializer from '~/components/_app/AppInitializer';
import ComposedContextProvider from '~/components/_app/ComposedContextProvider';
import GoogleTagManager from '~/components/_app/GoogleTagManager';
import { getNavAndFooterLayout } from '~/components/_layouts/NavAndFooterLayout';
import LeadFilter from '~/components/_routes/LeadFilter';
import config from '~/config';
import { AuthModalProvider } from '~/hooks/useAuthModal';
import useRouteProps from '~/hooks/useRouteProps';
import { GlobalStyle } from '~/styles/global';
import theme from '~/styles/theme';
import type { ErrorPageProps, GetLayout, Page } from '~/types/next';
import type { DataLayerObject } from '~/utils/analytics';
import { pushToDataLayer } from '~/utils/analytics';
import { inIFrame } from '~/utils/iFrameUtils';
import { storageAvailable } from '~/utils/localStorage';
import { useApollo } from '../apollo/setupApolloClient';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import type { DefaultTheme } from 'styled-components';
import '../styles.css';

const ThirdPartyCookiesDisabledMessage = dynamic(
  () => import('~/components/_app/UnsupportedMessage'),
);

// Warn about running "yarn start" locally
if (
  typeof window === 'undefined' &&
  config.isDeveloping &&
  process?.env.NODE_ENV === 'production' &&
  !(global as any).LOCAL_PROD_WARNING_DONE
) {
  (global as any).LOCAL_PROD_WARNING_DONE = true;
  console.warn(
    '\x1b[33m%s\x1b[0m',
    'Running a local production build. Did you mean to run "yarn dev" instead of "yarn start"?',
  );
}

// Inform about the environment
if (typeof window === 'undefined') {
  console.info('\tRunning in the', `\x1b[36m${config.environment}\x1b[0m`, 'environment');
}

const InfoBanners = dynamic(() => import('~/components/_banners/InfoBanners'), { ssr: false });
const ConsentModal = dynamic(() => import('~/components/_app/ConsentModal'), { ssr: false });
const CookieCard = dynamic(() => import('~/components/_app/CookieModal'), { ssr: false });
const Error = dynamic(() => import('~/pages/_error'));

type MyAppProps<P = Record<string, any>> = AppProps<P> & {
  Component: Page<P>;
  pageProps: ErrorPageProps;
};

const defaultGetLayout: GetLayout = getNavAndFooterLayout;

function MainApp({ Component, pageProps, router }: MyAppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  // Per-page layouts: https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  // TypeScript version from https://dev.to/carloschida/comment/1h99b
  // Use the layout defined at the page level, if available, otherwise fall back to default
  const getLayout = Component.getLayout ?? defaultGetLayout;

  // Get route props from routes.js file
  const routeProps = useRouteProps();

  // Ignored by the router
  const ignoredPaths = ['/blog'];
  if (
    !routeProps &&
    !config.isProduction &&
    !ignoredPaths.some(path => router.route.startsWith(path))
  ) {
    // TODO: At runtime, visitors will land on our custom paths defined in routes.js, which works fine here,
    // but when building the application, NextJS will render the pages with their english file names
    // That's why the typeof window check is in here
    console.warn('No route match found for', router.route);
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme as DefaultTheme}>
        <FlagsProvider config={config.unleash}>
          <AppHelmet />

          <GoogleTagManager />

          <CookiesProvider>
            <ApolloProvider client={apolloClient}>
              <IntercomProvider appId={config.keys.intercom.appId} autoBoot initializeDelay={3000}>
                <ComposedContextProvider initialMe={pageProps.me}>
                  <AppInitializer>
                    <AuthModalProvider>
                      <GlobalStyle />
                      <InfoBanners />

                      {/* For certain routes, you must be logged out (visitor-only) */}
                      <LeadFilter visitorRoute={routeProps?.visitorOnly ?? false}>
                        {/* errorCode can be provided by routeAuth when server-side requests fail*/}
                        {pageProps.errorCode ? (
                          <Error statusCode={pageProps.errorCode} />
                        ) : (
                          getLayout(
                            <Component {...pageProps} {...routeProps} />,
                            pageProps,
                            routeProps,
                          )
                        )}
                      </LeadFilter>

                      <ConsentModal />
                      <CookieCard />
                    </AuthModalProvider>
                  </AppInitializer>
                </ComposedContextProvider>
              </IntercomProvider>
            </ApolloProvider>
          </CookiesProvider>
        </FlagsProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

/**
 * @returns Whether the environment variables need to be fetched from the server
 * Only needed when running on the server or when the hostname is not in the HostnameEnvs object
 */
function needsEnvFetch() {
  const isServer = typeof window === 'undefined';
  return isServer || location.hostname in config.hostnameEnvs;
}

/**
 * Wraps the MainApp in an environment fetcher
 */
function MainAppWithEnv({ Component, pageProps, router }: MyAppProps) {
  const [isReady, setIsReady] = useState(!needsEnvFetch());

  useEffect(() => {
    if (!needsEnvFetch()) return;
    fetch('/api/env')
      .then(res => res.json())
      .then(res => config.reinitializeWithEnvironment(res.env))
      .catch(err => console.error('Error fetching environment variables:', err))
      .finally(() => setIsReady(true));
  }, []);

  return !isReady ? null : <MainApp Component={Component} pageProps={pageProps} router={router} />;
}

// https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals(metric: NextWebVitalsMetric) {
  const event: DataLayerObject = {
    event: 'web_vitals',
    event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js metric',
    event_action: metric.name,
    event_label: metric.id,
    value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
    non_interaction: true,
  };
  if (typeof window !== 'undefined' && window.dataLayer) pushToDataLayer(event);
}

/**
 * Users can disable support for site data (sometimes bundled in 3rd party cookie block), breaking local and session storage
 *
 * TODO: alternative: abstract local/session storage so we can easily fall back to in-memory storage.
 *   e.g. a Storage class that uses localStorage when available
 * Should also replace the apollo-cache with InMemoryCache
 */
const showThirdPartyCookieMessage = inIFrame() && !storageAvailable();

const App = showThirdPartyCookieMessage ? ThirdPartyCookiesDisabledMessage : MainAppWithEnv;

export default App;
