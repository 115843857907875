import { inIFrame } from './iFrameUtils';

/**
 * Important: Only use this for queries!
 * For any conditional rendering, use router.params after checking router.isReady,
 * so that the client and server render the same content
 */
export const isUserlessSession = (): boolean => {
  if (typeof window === 'undefined') return false;

  const params = Object.fromEntries(new URLSearchParams(location.search));

  return Boolean(inIFrame() && !params.houseId && !params.bbRef);
};
