import { gql } from '@apollo/client';

export const DiscountFragment = gql`
  fragment Discount on Discount {
    amount
    text
    type
  }
`;

export default DiscountFragment;
